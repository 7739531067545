<template>
    <b-modal id="canny-modal" size="xl" hide-footer @shown="loadCanny">
        <template #modal-header>
            <div class="modal-header bg-primary p-4 d-flex justify-content-between align-items-center">
                <h5 class="modal-title text-white">Feedback</h5>
                <svg
                    class="close-icon"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="$bvModal.hide('canny-modal')"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        stroke="white"
                    />
                </svg>
            </div>
        </template>
        <div v-if="loading" class="d-flex justify-content-center align-items-center canny">
            <b-spinner variant="primary" label="Saving" style="width: 6rem; height: 6rem" />
        </div>
        <div data-canny :style="loading ? 'overflow: hidden; height: 0' : 'overflow: auto; height: 600px'" />
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
const BoardToken = 'f9d763a1-a144-03bb-b519-267cbdaff374'

export default defineComponent({
    name: 'Canny',
    data() {
        return { loading: false }
    },
    methods: {
        loadCanny() {
            this.loading = true
            ;(function (w, d, i, s) {
                function l() {
                    if (!d.getElementById(i)) {
                        var f = d.getElementsByTagName(s)[0],
                            e = d.createElement(s)
                        ;(e.type = 'text/javascript'),
                            (e.async = !0),
                            (e.src = 'https://canny.io/sdk.js'),
                            f.parentNode.insertBefore(e, f)
                    }
                }
                if ('function' !== typeof w.Canny) {
                    var c = function () {
                        c.q.push(arguments)
                    }
                    ;(c.q = []),
                        (w.Canny = c),
                        'complete' === d.readyState
                            ? l()
                            : w.attachEvent
                              ? w.attachEvent('onload', l)
                              : w.addEventListener('load', l, !1)
                }
            })(window, document, 'canny-jssdk', 'script')

            Canny('render', {
                boardToken: BoardToken,
                basePath: null,
                ssoToken: null,
                theme: 'light',
                onLoadCallback: () => (this.loading = false),
            })
        },
    },
})
</script>

<style scoped>
#canny-modal___BV_modal_outer_ {
    z-index: 3147483647 !important;
}

.close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

::v-deep .modal-header {
    padding: 0;
    width: 100%;
}

.canny {
    width: 100%;
    height: 600px;
    overflow: auto;
}
</style>
